import React from "react"
import { Link, graphql } from "gatsby"
import { getCurrentLangKey } from "ptz-i18n"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

class BlogIndex extends React.Component {
  render() {
    const { data, location } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    const url = location.pathname
    const { langs, defaultLangKey } = data.site.siteMetadata.languages
    const langKey = getCurrentLangKey(langs, defaultLangKey, url)

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="All posts" />
        <div className="container w-11/12 lg:w-7/12">
          <div className="mb-16">
            <Bio currentLangKey={langKey} />
          </div>
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            return (
              <div className="mb-12">
                <article key={node.fields.slug}>
                  <header>
                    <h3 className="text-blue-500 mb-2 font-bold text-2xl no-underline">
                      <Link className="no-underline" to={node.fields.slug}>
                        {title}
                      </Link>
                    </h3>
                    <small>{node.frontmatter.date}</small>
                  </header>
                  <section>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: node.frontmatter.description || node.excerpt,
                      }}
                    />
                  </section>
                </article>
              </div>
            )
          })}
        </div>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        languages {
          defaultLangKey
          langs
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fields: {
          draft: { eq: false }
          langKey: { eq: "en" }
          slug: { regex: "/(blog)/" }
        }
      }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
